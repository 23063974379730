import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  collapsedDrawerWidth,
  openDrawerWidth,
} from "../../../constants/sideBarwidth";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { SearchPatient } from "../../../components/misc/SearchPatient";
import { FileUpload, Visibility } from "@mui/icons-material";
import PatientInfo from "../components/PatientInfo";
import InsuranceInfo from "../components/InsuranceInfo";
import { readOnlyBackground } from "../../../Theme/theme";
import PAPDevice from "../../../components/patientsComponent/dmeOrder/PAPDevice";
import MaskInterFace from "../../../components/patientsComponent/dmeOrder/MaskInterFace";
import PressureComponent from "../../../components/patientsComponent/dmeOrder/PressureComponent";
import InterfaceSupplies from "../../../components/patientsComponent/dmeOrder/InterfaceSupplies";
import DiagnosisCode from "../../../components/patientsComponent/dmeOrder/DiagnosisCodeDME";
import PhysicianPulldown from "../../../components/misc/PhysicianPulldown";
import FollowUpPhysicianPulldown from "../../../components/misc/FollowUpPhysicianPulldown";
import FollowingPhysicianPulldown  from "../../../components/misc/FollowingPhysicianPulldown";
import phoneNumberValidator from "../../../utils/phoneNumberValidator";
import {
  notificationToastError,
  notificationToastSuccess,
} from "../../../utils/notification";
import {
  dmeOrderPostData,
  getHeaderData,
  patientsPaginationGetData,
  patientsPostData,
} from "../../../axios/api";
import { useAxios } from "../../../hooks/auth";
import {
  isDiagnosisCode,
  isInterfaceSupplies,
  isMaskInterface,
} from "../../../utils/dmeOrderFnc";
import { ToastContainer } from "react-toastify";
import useIsPrevileges from "../../../hooks/useRoleAndPrivileges";
import SignedOrder from "../components/SignedOrder";
import { useLocation, useNavigate } from "react-router-dom";
import CloseRejectDialog from "../components/CloseRejectDialog";
import DateToVisit from "../components/Order/DateToVisit";
import moment from "moment";
import { base64ToPdf } from "../../../utils/base64ToPDFConvertor";
import FileView from "../components/FileUpload/FileView";
import dayjs from "dayjs";
import Documentupload from "../../../components/adminComponent/documents/documentUpload/Documentupload";
import SignDmeOrderHTMLString from "../../../utils/SignDmeOrderHTMLString";
import jsPDF from "jspdf";
  

function DmeOrder({
  surveyAndOrderId = {},
  isOrderModal = false,
  handleClose = () => {},
  data,
  followUpOrder = false,
}) {

  const isMobile = useMediaQuery("(max-width:800px)");
  const axios = useAxios();
  const privelegesArray = useIsPrevileges();
  const location = useLocation();
  const isSignOrderPresent =
    privelegesArray?.privileges?.subtab?.includes("signOrder");
  const [viewRejectState, setViewRejectDialogState] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isOrderSignedSucess, setIsOrderSignedSucess] = useState(false);
  const [patientsPhoneNumber, setPatientsPhoneNumber] = useState({
    bestPhone: "",
    cellPhone: "",
    bestPhoneError: false,
    cellPhoneError: false,
  });
  const { isCollapsed } = useSelector((state) => state.sideBarCollapseSelector);
  const selectedPatient = useSelector(
    (state) => state.selectedPatientSelector?.value
  );
  const navigate = useNavigate();
  const dmeStateData = location?.state?.row;
  const dmeStateDataForModel = location?.state?.patientInfo ?? {};
  const [visitDateState, setVisitDate] = useState(new Date());
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const [pdfURLState, setPdfURLState] = useState({ fileUrl: "", fileType: "" });
  const [BMI, setBMIState] = useState({});
  const [pdfURL, setPdfURL] = useState("");

  
  const handleSignedFileUpload = (dataUrl) => {
    let fileName;
    fileName = `SignedDmeOrder.pdf`;
    const payload = {
      file: dataUrl,
      fileName: fileName,
      patientId: selectedPatient?.patientId || formState?.patientId,
      signedOrder: 1,
      stageId: formState.stageId,
      orderId: formState.orderId,
      dmeOrderId: formState?.dmeOrderId || dmeStateData?.[164]
    };
    patientsPostData(axios, "uploadFile", payload)
      .then((res) => {
        if (res.data.response === "1") {
          // notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => notificationToastError(err?.message))
      .finally(() => handleClose());
  };

  const generatePdf = (signature, headerDetail, headerLogo, action) => {
    let htmlString;
    let inssuranceData = {
      primaryInsurance : formState.primaryInsuranceName,
      secondaryInsurance : formState.secondaryInsuranceName
    }
        
      
      htmlString = SignDmeOrderHTMLString({
        signature,
        formState: htmlPdfData,
        visitDateState,
        inssuranceData,
        physicianName: dmeStateData[305] || "",
        headerDetail,
        headerLogo,
        action,
        dmeOrderId: dmeStateData?.[164] || ""
      });

    //   const tempDiv = document.createElement('div');
    // // Ensure styles are applied; for example, ensure it's not `display: none` or similar
    // tempDiv.style.visibility = 'hidden';
    // tempDiv.style.position = 'absolute';
    // tempDiv.innerHTML = htmlString;

    // document.body.appendChild(tempDiv);

    const pdf = new jsPDF("p", "pt", [845,1000]);
      pdf.html(htmlString, {
        callback: function (file) {
          let dataUrl = pdf.output("datauristring");
          dataUrl = dataUrl?.split(";");
          dataUrl = [dataUrl[0], dataUrl[2]]?.join(";");
          if (dataUrl) {
            if (!action) handleSignedFileUpload(dataUrl);
            let base64 = pdf.output("datauristring");
            base64 = base64?.split(",")[1];
            const pdfUrl = base64ToPdf(base64);
            setPdfURL(pdfUrl);
            // setOpenPdf(true);
            let fileName;
            fileName = `dme_order_${formState.patientNum}_${moment(visitDateState).format('MM/DD/YYYY')}.pdf`;
            const link = document.createElement("a");
            link.href = pdfUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // revoke the temporary URL to free up memory
            URL.revokeObjectURL(pdfUrl);
          }
        },
        html2canvas: {
          scale: 0.8,
        },
        margin: [10, 10],
      });
  };

  const downloadPdf = (signature, action) => {

    getHeaderData(axios, "getDmeOrderHeader", { dmeOrderId : formState?.dmeOrderId || dmeStateData?.[164] })
          .then((response) => {
            if (response) {
             if (response[0][0]?.logoFileId !== null) {
                patientsPostData(axios, "downloadFile", {
                  id: response[0][0]?.logoFileId, tableName: "storedFiles", idColumn: "fileId",
                }).then((imgres) => {
                  if (imgres.data.file) {
                    generatePdf(signature, response, imgres?.data?.file,action);
                  } else {
                    notificationToastError("File was not downloaded due to issue.");
                  }
                }).catch((err) => notificationToastError(err?.message))
              } else {
                generatePdf(signature, response, "", action);
             }
             
           }

          })
          .catch((err) => {
            notificationToastError(err?.message);
          })
  };


  useEffect(() => {
    if (followUpOrder) {
      let payload = {};
      payload = {
        patientId: data[0] || data?.patientId,
        physicianId: data?.followupPhysicianId  ,
      };
      if (payload?.patientId) {
        patientsPaginationGetData(axios, "patientView", payload)
          .then((res) => {
            if (res?.length) {
              const patientData = res[0];

              const {
                formattedPhoneNumber: bestPhone,
                numericPhoneNumber: bestPhoneNumeric,
              } = phoneNumberValidator(patientData?.bestPhone);
              const {
                formattedPhoneNumber: cellPhone,
                numericPhoneNumber: cellPhoneNumeric,
              } = phoneNumberValidator(patientData?.cellPhone);
              setPatientsPhoneNumber({
                ...patientsPhoneNumber,
                bestPhone: bestPhoneNumeric,
                cellPhone: cellPhoneNumeric,
              });
              setFormState((prevState) => ({
                ...prevState,
                ...patientData,
                physicianId: data?.followupPhysicianId || res?.[1]?.followingPhysId,
                bestPhone,
                cellPhone,
              }));
              setDOB(patientData?.dob);
            }
          })
          .catch((err) => {
            console.log("err", err);
          })
          .finally(() => {});
      }
    }
    // viewDmeOrder
    if (
      dmeStateData?.length ||
      Object.keys(surveyAndOrderId)?.length ||
      Object.keys(dmeStateDataForModel)?.length ||
      selectedPatient
    ) {
      const {
        formattedPhoneNumber: bestPhone,
        numericPhoneNumber: bestPhoneNumeric,
      } = phoneNumberValidator(selectedPatient?.bestPhone);
      const {
        formattedPhoneNumber: cellPhone,
        numericPhoneNumber: cellPhoneNumeric,
      } = phoneNumberValidator(selectedPatient?.cellPhone);
      let payload = {};
      if (dmeStateData?.length) {
        payload = {
          patientId: selectedPatient?.patientId || dmeStateData?.[0],
          dmeOrderId: dmeStateData?.[164],
        };
        setFormState({
          ...formState,
          bestPhone,
          cellPhone,
          fileId: (dmeStateData && dmeStateData?.[266]) || 0,
          physicianName: (dmeStateData && dmeStateData?.[305]) || "",
        })
        setPatientsPhoneNumber({
          ...patientsPhoneNumber,
          bestPhone: bestPhoneNumeric,
          cellPhone: cellPhoneNumeric,
        });
        setBMIState({
          ...BMI,
          heightFeet: selectedPatient?.heightFeet,
          heightInches: selectedPatient?.heightInches,
          patBMI: selectedPatient?.patBMI,
          patWeight: selectedPatient?.patWeight,
        });
        if (selectedPatient?.dob) {
          setDOB(selectedPatient?.dob);
        }
      }else if(selectedPatient){
        setFormState({
          ...formState,
          ...selectedPatient,
          bestPhone,
          cellPhone,
        })
        setPatientsPhoneNumber({
          ...patientsPhoneNumber,
          bestPhone: bestPhoneNumeric,
          cellPhone: cellPhoneNumeric,
        });
        setBMIState({
          ...BMI,
          heightFeet: selectedPatient?.heightFeet,
          heightInches: selectedPatient?.heightInches,
          patBMI: selectedPatient?.patBMI,
          patWeight: selectedPatient?.patWeight,
        });
        if (selectedPatient?.dob) {
          setDOB(selectedPatient?.dob);
        }
        payload = {
          patientId: selectedPatient?.patientId || surveyAndOrderId?.patientId,
          dmeOrderId: surveyAndOrderId?.dmeOrderId,
        };
      } else if (Object.keys(dmeStateDataForModel)?.length) {
        payload = {
          patientId: selectedPatient?.patientId,
          dmeOrderId:
            dmeStateDataForModel?.dmeOrderId || surveyAndOrderId?.dmeOrderId || dmeStateData?.[164],
        };
      } else {
        payload = {
          patientId: selectedPatient?.patientId || surveyAndOrderId?.patientId,
          dmeOrderId: surveyAndOrderId?.dmeOrderId,
        };
      }

      dmeOrderPostData(axios, "viewDmeOrder", payload)
        .then((res) => {
          let data = res?.data;
          if (data?.length) {
            data = data[0];
            setDmeOrderInterface({
              All: data?.All,
              A7035: data?.A7035,
              A7037: data?.A7037,
              A4604: data?.A4604,
              E0562: data?.E0562,
              A7046: data?.A7046,
              A7038: data?.A7038,
              A7039: data?.A7039,
              A9279: data?.A9279,
              A7036: data?.A7036,
              RT94660: data?.RT94660,
            });
            setDmeOrderDiagnosisCode({
              G47_33: data?.G47_33,
              G47_34: data?.G47_34,
              other: data?.other,
              G47_31: data?.G47_31,
              I50_20: data?.I50_20,
              J44_0: data?.J44_0,
              J43_1: data?.J43_1,
              I50_20_desc: data?.I50_20_desc,
              J44_0_desc: data?.J44_0_desc,
              J43_1_desc: data?.J43_1_desc,
              otherNote: data?.otherNote
            });
            setDmeOrderState({
              papDevice: data?.papDevice,
              A7031: data?.A7031,
              A7030: data?.A7030,
              A7032: data?.A7032,
              A7034: data?.A7034,
              allItemMasked: data?.allItemMasked,
              titrate: data?.titrate,
              physicianId: data?.physicianId,
              addNotes: data?.addNotes,
              otherDesc: data?.otherDesc,
              ipapPress: data?.ipapPress,
              epapPress: data?.epapPress,
              rate: data?.rate,
              tidalVol: data?.tidalVol,
              estTimeramp: data?.estTimeramp,
            });
            patientsPaginationGetData(axios, "patientView", {
              patientId: payload.patientId || dmeStateData?.[0],
            })
              .then((response) => {
                if (response?.length) {
                  const patientState = response[0];
                  setFormState({
                    ...formState,
                    ...selectedPatient,
                    ...patientState,
                    dmeOrderId: data?.dmeOrderId || dmeStateData?.[164],
                    stageId:
                      (dmeStateData && dmeStateData[95]) || data?.statusNameId,
                    statusNameId:
                      (dmeStateData && dmeStateData[96]) || data?.statusNameId,
                    fileId: (dmeStateData && dmeStateData?.[266]) || 0,
		    dmeOrderSigned: data?.dmeOrderSigned,
                    bestPhone,
                    cellPhone,
                  });
                  setDOB(selectedPatient?.dob || patientState?.dob);
                  setBMIState({
                    ...BMI,
                    heightFeet: patientState?.heightFeet,
                    heightInches: patientState?.heightInches,
                    patBMI: patientState?.patBMI,
                    patWeight: patientState?.patWeight,
                  });
                }
              })
              .catch((err) => {
                console.log("err", err);
              })
              .finally(() => {});
          }
        })
        .catch((err) => {
          console.log("err==>", err);
        });
    }
  }, []);


  //   useState
  const [formState, setFormState] = useState({});

  const [dmeOrderState, setDmeOrderState] = useState({});


  const [open, setOpen] = useState(false);
  const [dmeOrderInterface, setDmeOrderInterface] = useState({
    All: 0,
    A7035: 0,
    A7037: 0,
    A4604: 0,
    E0562: 0,
    A7046: 0,
    A7038: 0,
    A7039: 0,
    A9279: 0,
    A7036: 0,
    RT94660: 0,
  });

  const [dmeOrderDiagnosisCode, setDmeOrderDiagnosisCode] = useState({
    G47_33: 0,
    G47_34: 0,
    other: 0,
    G47_31: 0,
    I50_20: 0,
    J44_0: 0,
    J43_1: 0,
    I50_20_desc: "",
    J44_0_desc: "",
    J43_1_desc: "",
    otherNote: ""
  });

  const [formErrorState, setFormErrorState] = useState({
    physicianId: false,
    papDeviceError: false,
    maskInterFaceError: false,
    interphaseSuppliesError: false,
    diagnosisCodeError: false,
  });

  const htmlPdfData = {
    ...formState,
    ...dmeOrderState,
    ...dmeOrderDiagnosisCode,
    ...dmeOrderInterface,
  };

  let viewDmeOrder;
  if (dmeOrderState?.length > 0) viewDmeOrder = 1;
  else viewDmeOrder = 0;

  const [DOB, setDOB] = React.useState();

  //   update functions
  const updateInput = (e) => {
    if (formErrorState?.papDeviceError) {
      setFormErrorState({
        ...formErrorState,
        papDeviceError: false,
      });
    }
    setDmeOrderState({
      ...dmeOrderState,
      [e.target.id || e.target.name]: e.target.value || e.target.checked,
    });

    setFormState({
      ...formState,
      [e.target.id || e.target.name]: e.target.value || e.target.checked,
    });

    setIsSaveButtonDisabled(false);
  };
  // update mask interface
  const updateCheckBox = (e) => {
    if (formErrorState?.maskInterFaceError) {
      setFormErrorState({
        ...formErrorState,
        maskInterFaceError: false,
      });
    }
    const { name, checked } = e.target;

    setDmeOrderState((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: Number(checked),
      };
      if (!checked) {
        if (name === "A7032") {
          updatedState["A7034"] = 0;
          updatedState["allItemMasked"] = 0;
        } else if (name === "A7034") {
          updatedState["A7032"] = 0;
          updatedState["allItemMasked"] = 0;
        } else if (name === "A7030") {
          updatedState["A7031"] = 0;
          updatedState["allItemMasked"] = 0;
        } else if (name === "A7031") {
          updatedState["A7030"] = 0;
          updatedState["allItemMasked"] = 0;
        } else if (name === "allItemMasked") {
          const allChecked = Number(checked);
          updatedState["A7032"] = allChecked;
          updatedState["A7034"] = allChecked;
          updatedState["A7030"] = allChecked;
          updatedState["A7031"] = allChecked;
        }
      } else {
        // If the clicked checkbox is being checked, check the corresponding checkbox
        if (name === "A7032") {
          updatedState["A7034"] = 1;
          updatedState["A7030"] = 0;
          updatedState["A7031"] = 0;
        } else if (name === "A7034") {
          updatedState["A7032"] = 1;
          updatedState["A7030"] = 0;
          updatedState["A7031"] = 0;
        } else if (name === "A7030") {
          updatedState["A7031"] = 1;
          updatedState["A7032"] = 0;
          updatedState["A7034"] = 0;
        } else if (name === "A7031") {
          updatedState["A7030"] = 1;
          updatedState["A7032"] = 0;
          updatedState["A7034"] = 0;
        } else if (name === "allItemMasked") {
          const allChecked = Number(checked);
          updatedState["A7032"] = allChecked;
          updatedState["A7034"] = allChecked;
          updatedState["A7030"] = allChecked;
          updatedState["A7031"] = allChecked;
        }
      }
      return updatedState;
    });

    setIsSaveButtonDisabled(false);
  };

  // update interface supplies
  const updatedInterfaceCheckbox = (event) => {
    if (formErrorState?.interphaseSuppliesError) {
      setFormErrorState({
        ...formErrorState,
        interphaseSuppliesError: false,
      });
    }
    const { name, checked } = event.target;

    if (name === "All" && checked) {
      setDmeOrderInterface((prevVal) => {
        const updatedState = { ...prevVal };
        Object.keys(updatedState).forEach((key) => {
          updatedState[key] = Number(checked);
        });

        if (!updatedState.A7037 && !updatedState.A4604) {
          updatedState.A7037 = 1;
        }

        if (updatedState.A7037 && updatedState.A4604) {
          updatedState.A4604 = 0;
        }

        return updatedState;
      });
    }
    if (name === "All" && !checked) {
      setDmeOrderInterface((prevVal) => {
        const updatedState = { ...prevVal };
        Object.keys(updatedState).forEach((key) => {
          updatedState[key] = Number(checked);
        });

        return updatedState;
      });
    } else {
      setDmeOrderInterface((prevVal) => {
        let updatedState = {
          ...prevVal,
          [name]: Number(checked),
        };

        if (!checked) {
          updatedState.All = 0;
        }

        if (name === "A7037" && checked) {
          updatedState = {
            ...updatedState,
            A4604: 0,
          };
        } else if (name === "A4604" && checked) {
          updatedState = {
            ...updatedState,
            A7037: 0,
          };
        }

        if (name === "E0562" && checked) {
          updatedState = {
            ...updatedState,
            A7046: 1,
          };
        } else if (name === "A7046" && checked) {
          updatedState = {
            ...updatedState,
            E0562: 1,
          };
        } else if (name === "E0562" || (name === "A7046" && !checked)) {
          updatedState = {
            ...updatedState,
            E0562: 0,
            A7046: 0,
          };
        }
        return updatedState;
      });
    }

    setIsSaveButtonDisabled(false);
  };

  // update diagnosis code
  const updatedDiagnosisCheckbox = (event) => {
    if (formErrorState?.diagnosisCodeError) {
      setFormErrorState({
        ...formErrorState,
        diagnosisCodeError: false,
      });
    }
    const { name, checked } = event.target;

    setDmeOrderDiagnosisCode((prevVal) => ({
      ...prevVal,
      [name]: checked ? 1 : null,
    }));

    setIsSaveButtonDisabled(false);
  };

  const handleDiagnosisValue = (event) => {
    const { name, value } = event.target;

    setDmeOrderDiagnosisCode((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    setIsSaveButtonDisabled(false);
  };


  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const errorHandler = (field) => {
    setFormErrorState((prevState) => ({
      ...prevState,
      [field]: { error: true },
    }));
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField(key)) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const saveDmeOrder = (e, newOrderSigned) => {
    let isValid = true;
    const { papDevice } = dmeOrderState;

    if (!papDevice) {
      isValid = false;
      errorHandler("papDeviceError");
    }
    if (!dmeOrderState?.physicianId && !formState?.physicianId) {
      isValid = false;
      errorHandler("physicianId");
    }

    if (!isMaskInterface(dmeOrderState)) {
      isValid = false;
      errorHandler("maskInterFaceError");
    }

    if (!isInterfaceSupplies(dmeOrderInterface)) {
      isValid = false;
      errorHandler("interphaseSuppliesError");
    }

    if (!isDiagnosisCode(dmeOrderDiagnosisCode)) {
      isValid = false;
      errorHandler("diagnosisCodeError");
    }

    if (!isValid) {
      notificationToastError("Please fill all required fields");
    }

    if(moment(new Date(visitDateState)).format("YYYY/MM/DD") == "Invalid date"){
      isValid = false;
      notificationToastError( "Invalid date format");
    }

    if (isValid) {
      let payload;
      if (followUpOrder) {
        payload = {
          ...dmeOrderState,
          ...dmeOrderInterface,
          ...dmeOrderDiagnosisCode,
          physicianId: formState?.physicianId,
          patientId: selectedPatient?.patientId,
          newOrderSigned: newOrderSigned || "",
          dmeOrderId: dmeStateData?.[164],
          orderId: data?.orderId,
          dmeVisitDate:
            visitDateState &&
            moment(new Date(visitDateState)).format("YYYY-MM-DD"),
          dmeOrderFollowingPhysicianId: formState?.physicianId,
          parentOrderId: data?.orderId,
          studyId: data?.studyId,
          followUpOrder: 1
        };
      } else {
        payload = {
          ...dmeOrderState,
          ...dmeOrderInterface,
          ...dmeOrderDiagnosisCode,
          patientId: selectedPatient?.patientId,
          newOrderSigned: newOrderSigned || "",
          dmeOrderId: dmeStateData?.[164],
          orderId: data?.[11],
          uploadSignedDmeOrder: formState?.file || 0 ,
          file: formState?.file || "" ,
          dmeVisitDate:
            visitDateState &&
            moment(new Date(visitDateState)).format("YYYY-MM-DD"),
        };
      }

      dmeOrderPostData(axios, "saveDmeOrder", payload)
        .then((res) => {
          if (res?.data?.response === "1") {
            if (e.target.id !== "signOrderBtn") {
            notificationToastSuccess(res?.data?.message);
            if (followUpOrder) {
              handleClose();
            } else {
              setTimeout(() => {
                navigate(`/dashboard`);
              }, 2000);
            }
          } else if (e.target.id !== "signOrderBtn") {
              // setIsSignOrderDisable(true);
              notificationToastSuccess(res?.data?.message);
              if (followUpOrder) {
                handleClose();
              } else {
                setTimeout(() => {
                  navigate(`/dashboard`);
                }, 2000);
              }
            } else if (e.target.id === "signOrderBtn") {
              setFormState({...formState,dmeOrderId : res?.data?.dmeOrderId || dmeStateData?.[164]})
              setOpen(true);
            }
            setIsSaveButtonDisabled(true);
            // setIsSignOrderDisable(true);
          } else {
            notificationToastError(res?.data?.message);
          }
        })
        .catch((err) => notificationToastError(err?.message));
    }
  };
  // reject dme order

  const openRejectDialog = () => {
    setViewRejectDialogState(true);
  };

  const reject = (reason) => {
    const payload = {
      stageId: dmeStateData ? (dmeStateData[95] ? dmeStateData[95] : "") : "",
      reason,
    };

    dmeOrderPostData(axios, "rejectDmeOrder", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => notificationToastError(err?.message));

    setViewRejectDialogState(false);
    setTimeout(() => {
      navigate(`/dashboard`);
    }, 2000);
  };

  const rejectDialog = () => {
    setViewRejectDialogState(false);
  };
  let orderFollowing;
  if (dmeStateData?.[244] > 0) orderFollowing = true; else orderFollowing = false;

  const download = (id, tableName, idColumn, isView) => {
    const payload = {
      id,
      tableName: tableName,
      idColumn: idColumn,
    };
    patientsPostData(axios, "downloadFile", payload)
      .then((res) => {
        if (res.data.file) {
          if (isView === "view") {
            setOpenPdfModal(true);
            let base64 = res?.data?.file;
            base64 = base64?.split(",")[1];
            let fileType = res?.data?.name;
            fileType = fileType?.split(".")?.pop();
            const pdfUrl = base64ToPdf(base64, fileType);
            setPdfURLState({
              fileUrl: pdfUrl,
              fileType,
            });
          } else {
            notificationToastSuccess("File is downloading.");
            const downloadLink = document.createElement("a");
            downloadLink.href = res.data.file;
            downloadLink.download = res.data.name;
            downloadLink.click();
          }
        } else {
          notificationToastError("File was not downloaded due to issue.");
        }
        // setRefreshData(!refreshData);
      })
      .catch((err) => notificationToastError(err?.message));
  };

  return (
    <Container
      maxWidth="false"
      sx={{ margin: isOrderModal && !isMobile ? "30px 10px" : "0px" }}
    >
      {selectedPatient && !isOrderModal && (
        <SearchPatient selectedPatient={selectedPatient} />
      )}
      {(!isOrderModal && formState?.fileId > 0) && (

        <Box sx={{ display: "flex", gap: 1, marginTop: "1rem" }}>
          <Typography
            variant="h6" > Click here to view Interpretation </Typography>
          <Tooltip title="View" placement="bottom">
            <Button
              sx={{ width: 60 }}
              variant="contained"
              size="small"
              onClick={download.bind(
                null,
                formState?.fileId,
                "storedFiles",
                "fileId",
                "view"
              )}
            >
              <Visibility />
            </Button>
          </Tooltip>
        </Box>

      )}

      {isOrderModal && (
        <Box
          data-testid="close-button"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: "20px",
            top: "10px",
            background: "#cdcfcf",
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
          }}
        >
          <CloseIcon sx={{ color: "#fff" }} />
        </Box>
      )}
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1 },
          border: "1px solid rgba(0,0,0, 0.12)",
          borderRadius: "4px",
          marginTop: isMobile ? "55px" : "20px",
          background: readOnlyBackground,
        }}
        noValidate
        autoComplete="off"
      >
        <PatientInfo
          formState={formState}
          // updateInput={updateInput}
          DOB={DOB}
          // updateDOB={updateDOB}
          // errorDOB={errorDOB}
          // setErrorDOB={setErrorDOB}
          // formErrorState={formErrorState}
          // setFormErrorState={setFormErrorState}
          isReadOnly={true}
          handleFocus={handleFocus}
          BMIState={BMI}
        />

        <InsuranceInfo
          formState={formState}
          setFormState={setFormState}
          isReadOnly={true}
          handleFocus={handleFocus}
        />
      </Box>
      <Grid container columnGap={1.5}>
        <Grid item xl={5.9} lg={5.9} md={5.7} xs={12} sm={12}>
          <PAPDevice
            updateInput={updateInput}
            errorState={formErrorState?.papDeviceError}
            dmeOrderState={dmeOrderState}
          />
        </Grid>
        <Grid item xl={5.9} lg={5.9} md={5.7} xs={12} sm={12}>
          <MaskInterFace
            updateCheckBox={updateCheckBox}
            dmeOrderState={dmeOrderState}
            errorState={formErrorState?.maskInterFaceError}
          />
        </Grid>
        <Grid item xl={5.9} lg={5.9} md={5.7} xs={12} sm={12}>
          <PressureComponent
            updateInput={updateInput}
            dmeOrderState={dmeOrderState}
            updateCheckBox={updateCheckBox}
          />
        </Grid>
        <Grid item xl={5.9} lg={5.9} md={5.7} xs={12} sm={12}>
          <InterfaceSupplies
            updateCheckBox={updatedInterfaceCheckbox}
            dmeOrderState={dmeOrderInterface}
            errorState={formErrorState?.interphaseSuppliesError}
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
          <DiagnosisCode
            dmeOrderState={dmeOrderDiagnosisCode}
            updateCheckBox={updatedDiagnosisCheckbox}
            inputHanldeChange={handleDiagnosisValue}
            errorState={formErrorState?.diagnosisCodeError}
          />
        </Grid>

        <Grid container spacing={2} sx={{ display: "flex",ml:"-8px" }}>
          <Grid item xl={5.95} lg={5.9} md={12} xs={12} sm={12} mt={1}>
            <TextField
              sx={{ width: "100%" }}
              label="Special/Additional instructions"
              variant="standard"
              name="addNotes"
              id="addNotes"
              value={dmeOrderState?.addNotes || ""}
              onChange={updateInput}
              inputProps={{ maxLength: 200 }}
            />
          </Grid>

          <Grid item xl={5.95} lg={5.9} md={12} xs={12} sm={12} mt={1}>
            <DateToVisit
              setVisitDate={setVisitDate}
              visitDateState={visitDateState}
              setIsSaveButtonDisabled={setIsSaveButtonDisabled}
            />
          </Grid>
        </Grid>

        <Grid item xl={5.95} lg={5.9} md={6} xs={6} sm={12} mt={1}>

        {orderFollowing  && (
            
              <FollowUpPhysicianPulldown
                formState={dmeOrderState}
                updateInput={updateInput}
                attributeName={"physicianId"}
                formErrorState={formErrorState}
                validateEmptyField={validateEmptyField}
              />
            )}

{followUpOrder && (
            
            <FollowingPhysicianPulldown
              formState={formState}
              updateInput={updateInput}
              attributeName={"physicianId"}
              formErrorState={formErrorState}
              validateEmptyField={validateEmptyField}
              followUpOrder = {true}
            />
          )}

     

          { (!followUpOrder && !orderFollowing) && (
       
            <PhysicianPulldown
              formState={dmeOrderState}
              updateInput={updateInput}
              attributeName={"physicianId"}
              formErrorState={formErrorState}
              // validateEmptyField={validateEmptyField}
            />
          )}
        </Grid>
        </Grid>
        {( formState?.statusNameId === 1 && !isOrderSignedSucess  ) &&  (
          <Box mt={2}>
          <Documentupload
                 data-testid="document-upload" // Add Cypress ID to the Documentupload component
                 setFormState={setFormState}
                 setIsSaveButtonDisabled={setIsSaveButtonDisabled}
                 formErrorState={formErrorState}
                 setFormErrorState={setFormErrorState}
                 formState={formState}
                 title={"Upload Signed Order"}
             />
             </Box>
          )}


      {isOrderSignedSucess || formState.statusNameId == 3 ? (
        <Box sx={{ padding: "20px 0px", maxWidth: "300px" }}>
          <Alert variant="filled" severity="success">
            This DME order has been signed
          </Alert>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            paddingBottom: isMobile ? "4rem" : "2rem",
          }}
        >
          <Button
            sx={{ margin: "20px 0px" }}
            variant="contained"
            disabled={isSaveButtonDisabled}
            onClick={saveDmeOrder}
          >
            SAVE DME ORDER
          </Button>
          <ToastContainer />

          {isSignOrderPresent && (
            <SignedOrder
              formState={htmlPdfData}
              isSignOrderDisable={isSaveButtonDisabled}
              setIsSaveButtonDisabled={setIsSaveButtonDisabled}
              setIsOrderSignedSucess={setIsOrderSignedSucess}
              saveOrder={saveDmeOrder}
              setOpen={setOpen}
              open={open}
              setFormState={setFormState}
              isDmeOrder={true}
            />
          )}
          {dmeStateData && dmeStateData[95] ? (
            <Button variant="contained" onClick={openRejectDialog}>
              Reject DME ORDER
            </Button>
          ) : (
            ""
          )}

{(formState?.statusNameId === 1) &&  (
      
      <Button
       variant="contained"
       onClick={downloadPdf.bind(null, "signature", "download")}
     >
       Download DME Order 
     </Button>
      
     
)}

          {viewRejectState && (
            <CloseRejectDialog
              handleSave={reject}
              handleClose={rejectDialog}
              {...viewRejectState}
            />
          )}

            {openPdfModal && (
              <FileView
                setOpenPdfModal={setOpenPdfModal}
                openPdfModal={openPdfModal}
                pdfURLState={pdfURLState}
              />
            )}
        </Box>
      )}
    </Container>
  );
}

export default DmeOrder;
