import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import * as React from "react";
import dayjs from "dayjs";
import Box from "@mui/material/Box";
import { useAuth, useAxios } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../components/misc/Dialog";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import PrefInterpPhysicianPulldown from "../../components/misc/PrefInterpPhysicianPulldown";
import StudyTypePulldown from "../../components/misc/StudyTypePulldown";
import { studyPostData } from "../../axios/api";
import { pulldownsPostData, orderPostData } from "../../axios/api";
import GeneralPulldown from "./pulldowns/GeneralPulldown";

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ReschedulePopup from "./ReschedulePopup";

export default function ScheduleStudyDialog({
  handleSave,
  handleCloseSchedule,
  row,
  setViewCloseDialogState = () => {},
  notificationToastSuccess = () => {},
  notificationToastError = () => {},
  asDialog = true,
  isPreviousVisits = false,
  studyData = {},
  isPatientPortal,
  notShowPopup,
  reloadCurrentVisit,
  isWellnecessitiesUser
}) {
  const [formErrorState, setFormErrorState] = useState({
    studyTypeId: "",
    locationId: "",
    SDATE: "",
    time: "",
    shipDate: ""
  });

  const [open, setOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const [SDATE, setSDATE] = React.useState(null);
  const [deviceReturnDate, setDeviceReturnDate] = useState(null);
  const [deviceReturnDateErr, setDeviceReturnDateErr] = useState(null);
  const axios = useAxios();
  const paramNameIn = "name";

  React.useEffect(() => {
    if (row) {
      const newFormState = {
        physicianId: row?.physicianIdOrder,
        patientId: row?.patientId,
        surveyId: row?.surveyIdSurvey,
        orderId: row?.orderIdOrder,
        stageId: row?.stageId,
        orderingPhysicianName: row?.name,
        prefInterpPhysId: row?.prefInterpPhysId,
        time: row?.time,
        primaryInsuranceId: row?.primaryInsuranceIdP,
        secondaryInsuranceId: row?.secondaryInsuranceIdP,
        primaryPolicy: row?.primaryPolicyP,
        primaryGroup: row?.primaryGroupP,
        secondaryPolicy: row?.secondaryPolicyP,
        secondaryGroup: row?.secondaryGroupP,
        hstDeviceId: row?.hstDeviceId,
        studyTypeId: row?.secondApptStudyTypeId || row?.studyTypeId ,
        deviceReturnDate: row?.deviceReturnDate,
        tertiaryInsuranceId: row?.tertiaryInsuranceIdP,
        tertiaryPolicy: row?.tertiaryPolicyP,
        tertiaryGroup: row?.tertiaryGroupP,
        preferredHstBrandId: row?.preferredHstBrandId
      };
      setFormState(newFormState);
      setDeviceReturnDate(row?.deviceReturnDate)
      setSDATE(row?.date);
      setSHIP(row?.shipDate);
      // Get study type from Order
      if (!row?.studyTypeId && !row?.secondApptStudyTypeId) {
        if (row?.orderIdOrder) {
          let payload;
          payload = {
            orderId: row?.orderIdOrder,
          };
          orderPostData(axios, "viewOrder", payload)
            .then((res) => {
              if (res?.data?.length) {
                const orderDetails = res?.data[0];

                let studyTypeId = "";
                if (orderDetails["2nightProtocol"]) {
                  studyTypeId = 1;
                } else if (orderDetails["splitNightProtocol"]) {
                  studyTypeId = 2;
                } else if (
                  orderDetails["psgOptions"] &&
                  orderDetails["psgOptions"] ===
                    "PSG with MSLT (Multiple Sleep Latency Test) 95805"
                ) {
                  studyTypeId = 4;
                } else if (
                  orderDetails["psgOptions"] &&
                  orderDetails["psgOptions"] ===
                    "PSG with MWT (Maintenance of Wakefulness Test) 95805"
                ) {
                  studyTypeId = 5;
                } else if (orderDetails["psgBaselineStudy"]) {
                  studyTypeId = 3;
                } else if (
                  orderDetails["hstNights"] &&
                  orderDetails["hstNights"] === "ONE NIGHT"
                ) {
                  studyTypeId = 8;
                } else if (
                  orderDetails["hstNights"] &&
                  orderDetails["hstNights"] === "TWO NIGHT"
                ) {
                  studyTypeId = 9;
                } else if (
                  orderDetails["hstNights"] &&
                  orderDetails["hstNights"] === "THREE NIGHT"
                ) {
                  studyTypeId = 10;
                } else if (
                  orderDetails["cpapOptions"] &&
                  orderDetails["cpapOptions"] ===
                    "MSLT (Multiple Sleep Latency Test) 95805"
                ) {
                  studyTypeId = 12;
                } else if (
                  orderDetails["cpapOptions"] &&
                  orderDetails["cpapOptions"] ===
                    "MWT (Maintenance of Wakefulness Test) 95805"
                ) {
                  studyTypeId = 13;
                } else if (orderDetails["cpapTitration"]) {
                  studyTypeId = 11;
                } else if (
                  orderDetails["bipapOptions"] &&
                  orderDetails["bipapOptions"] ===
                    "MSLT (Multiple Sleep Latency Test) 95805"
                ) {
                  studyTypeId = 15;
                } else if (
                  orderDetails["bipapOptions"] &&
                  orderDetails["bipapOptions"] ===
                    "MWT (Maintenance of Wakefulness Test) 95805"
                ) {
                  studyTypeId = 16;
                } else if (orderDetails["bipapTitration"]) {
                  studyTypeId = 14;
                } else if (orderDetails["bipapStTitration"]) {
                  studyTypeId = 17;
                } else if (orderDetails["AsvTitration"]) {
                  studyTypeId = 18;
                } else if (orderDetails["AvapsTitration"]) {
                  studyTypeId = 19;
                } else if (
                  orderDetails["pediatricOptions"] &&
                  orderDetails["pediatricOptions"] ===
                    "PSG BASELINE STUDY (95782 or 95810 Medicaid only)"
                ) {
                  studyTypeId = 21;
                } else if (
                  orderDetails["pediatricOptions"] &&
                  orderDetails["pediatricOptions"] ===
                    "CPAP titration (95783 or 95811 Medicaid only)"
                ) {
                  studyTypeId = 22;
                } else if (
                  orderDetails["pediatricOptions"] &&
                  orderDetails["pediatricOptions"] ===
                    "BiPAP titration (95783 or 95811 Medicaid only)"
                ) {
                  studyTypeId = 23;
                } else if (orderDetails["pediatricSleepStudy"]) {
                  studyTypeId = 20;
                }

                if (studyTypeId) {
                  const newFormStateWithStudyTypeId = {
                    ...newFormState,
                    studyTypeId,
                  };
                  setFormState(newFormStateWithStudyTypeId);
                }
              }
            })
            .catch((err) => {});
        }
      }

      if (newFormState?.studyTypeId) ScheduleLocation(newFormState);

    } else if (studyData) {
      // console.log("studyData=====>", studyData);
      setFormState({ ...formState, ...studyData, SDATE: studyData?.studyDate });
      setSDATE(studyData?.studyDate);
      setSHIP(studyData?.shipDate);
      setDeviceReturnDate(studyData.deviceReturnDate)
      if (studyData?.studyTypeId) ScheduleLocation(studyData);
    }
/*
    if (secondApptInfo) {
      const newFormStateSecondApptInfo = {
        ...secondApptInfo,
      };
      setFormState(newFormStateSecondApptInfo);
    }
    */
  }, [row]);

  const [locations, setLocations] = useState([]);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const updateInput = (e) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    const newFormState = {
      ...formState,
      [e.target.id || e.target.name]: e.target.value || "",
    };
    setFormState(newFormState);

    if (newFormState.studyTypeId && newFormState.prefInterpPhysId)
      ScheduleLocation(newFormState);
  };
  function ScheduleLocation(payload) {
    pulldownsPostData(axios, "scheduleLocationPulldown", payload)
      .then((res) => {
        if (res && res.data) setLocations(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const saveChanges = (e) => {
    e.preventDefault();
    let isValid = true;
    const errors = { ...formErrorState };
    for (const [key, _] of Object.entries(formErrorState)) {
      if (isEmptyField(key)) {
        if(key === 'shipDate'){
          if(!formState.shipDate && (formState?.studyTypeId === 8 || formState?.studyTypeId === 9 || formState?.studyTypeId === 10)){
            isValid = false;
            errors[key] = { error: true };
          }else{
            errors[key] = { error: false };
          }
        }else{
          isValid = false;
          errors[key] = { error: true };
          console.log(" error = ", key);
        }
      }
    }

    if ((formState?.studyTypeId === 8 || formState?.studyTypeId === 9 || formState?.studyTypeId === 10) && !deviceReturnDate){
      isValid = false;
      setDeviceReturnDateErr(true)
    }

    if (!isValid) {
      setFormErrorState(errors);
      return false;
    }

    saveStudy(formState);
  };

  const isEmptyField = (key) =>
    formState[key] === undefined ||
    formState[key] === null ||
    formState[key] === "";

  function validateEmptyField(key) {
    if (isEmptyField()) {
      setFormErrorState({ ...formErrorState, [key]: { error: true } });
    }
  }

  const saveStudy = () => {
    setOpen(true);
    setIsSaveButtonDisabled(true);
  /*  let secondAppt;
    if (secondApptInfo) secondAppt = 1;
    else secondAppt = "";
*/
    const payload = {
      ...formState,
      date: formState.SDATE,
      shipDate: formState.shipDate,
      deviceReturnDate: formState?.studyTypeId === 8 || formState?.studyTypeId === 9 || formState?.studyTypeId === 10 ? formState.deviceReturnDate : ""
    };
    studyPostData(axios, "saveStudy", payload)
      .then((res) => {
        if (res?.data?.response === "1") {
          notificationToastSuccess(res?.data?.message);
        } else {
          notificationToastError(res?.data?.message);
        }
      })
      .catch((err) => {
        notificationToastError(err?.message);
      });
    notificationToastSuccess("success");
    setTimeout(() => {
      setViewCloseDialogState(false);
    }, 2000);

    if (reloadCurrentVisit) reloadCurrentVisit(payload);
    
  };
  const handleFocus = (event) => {
    if (event.target.readOnly || event.target.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const [SHIP, setSHIP] = React.useState(null);
  const [errorSHIP, setErrorSHIP] = React.useState(false);
  const updateSHIP = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setSHIP(newValue.format("MM/DD/YYYY"));
    setFormState({ ...formState, shipDate: newValue.format("YYYY-MM-DD") });
  };

  const [errorSDATE, setErrorSDATE] = React.useState(false);
  const [errorDueDATE, setErrorDueDATE] = React.useState(false);
  const updateSDATE = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setSDATE(newValue.format("MM/DD/YYYY"));
    setFormState({ ...formState, SDATE: newValue.format("YYYY-MM-DD") });
  };

  //update device return due date
  const updateDueDate = (newValue) => {
    if (isSaveButtonDisabled) {
      setIsSaveButtonDisabled(false);
    }
    setDeviceReturnDate(newValue.format("MM/DD/YYYY"));
    setFormState({ ...formState, deviceReturnDate: newValue.format("YYYY-MM-DD") });
  };

  const updateTime = (newValue) => {
    setFormState({ ...formState, time: newValue.format("hh:mm a") });
  };

  //pickup handlechange
  const pickupCheckboxChange = (event) => {
    const checkboxId = event.target.id;

    formState[checkboxId] = event.target.checked ? 1 : 0;
    setFormState({ ...formState });
    setIsSaveButtonDisabled(false);
  };

  let patientNameVal;
  if (row?.patientName) {
    patientNameVal = row?.patientName;
  } else patientNameVal = formState?.patientName;

  if (asDialog) {
    return (
      <ConfirmDialog
        handleClose={handleCloseSchedule}
        confirmButton={{ action: saveChanges, title: "Save" }}
        isSaveButtonDisabled={isSaveButtonDisabled}
      >
        {renderForm(asDialog)}
      </ConfirmDialog>
    );
  } else {
    return renderForm(asDialog);
  }

  function renderForm(asDialog) {
    return (
      <div data-testid="schedule-study-data">
        <div>
          {" "}
          <FormControl sx={{ m: "8px 0px" }}>
            <Typography variant="h6" data-testid="appoinment-text">
              {" "}
              {!asDialog
                ? "Appointment Info :"
                : "Please schedule the patient in Somnoware, enter the appointment information below: "}
            </Typography>
          </FormControl>
        </div>
        {asDialog && !notShowPopup && (
          <ReschedulePopup row={row}/>
        )}
        <Box
          component="div"
          sx={{
            // "& .MuiFormControl-root": { flexGrow: 1 },
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            margin: "8px 0px",
          }}
        >
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Study Date *"
                inputFormat="MM/DD/YYYY"
                className="study-date-data"
                value={SDATE || ""}
                onChange={updateSDATE}
                readOnly={isPreviousVisits}
                renderInput={(params) => (
                  <TextField
                    // data-testid="study-date-data"
                    {...params}
                    variant="standard"
                    error={formErrorState.SDATE?.error && !SDATE}
                    helperText={
                      formErrorState.SDATE?.error && !SDATE ? "required" : ""
                    }
                    value={
                      SDATE ? moment.utc(SDATE.toLocaleString()).format("MM/DD/YYYY") : ""
                    }
                    onBlur={() => {
                      setErrorSDATE(!SDATE);
                    }}
                    inputProps={{ readOnly: isPreviousVisits }}
                    onMouseDown={handleFocus}
                    placeholder="mm/dd/yyyy"
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl variant="standard" sx={{ width: "50%" }}>
            <TextField
              id="time"
              variant="standard"
              label="Select Time"
              type="time"
              defaultValue="08:00"
              value={formState.time || ""}
              required
              error={formErrorState.time?.error && !formState.time}
              onChange={updateInput}
              InputLabelProps={{
                shrink: true,
              }}
              readOnly={isPreviousVisits}
            />
            {formErrorState?.time?.error && !formState?.time && (
              <Typography variant="subtitle2" color={"error"}>
                required
              </Typography>
            )}
          </FormControl>
        </Box>
        <StudyTypePulldown
          key="studyTypeId"
          isPreviousVisits={isPreviousVisits}
          formState={formState}
          updateInput={updateInput}
          formErrorState={formErrorState}
          attributeName="studyTypeId"
          style={{ width: "100%" }}
          isPatientPortal={isPatientPortal}
        />

        {(formState?.studyTypeId === 8 ||
          formState?.studyTypeId === 9 ||
          formState?.studyTypeId === 10) && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
              margin: "8px 0px",
              alignItems: "center"
            }}
          >
            <>
              <GeneralPulldown
                key="hstDeviceId"
                isPreviousVisits={isPreviousVisits}
                formState={formState}
                updateInput={updateInput}
                formErrorState={formErrorState}
                attributeName="hstDeviceId"
                style={{ width: "100%" }}
                isPatientPortal={isPatientPortal}
                pulldownApiName="hstDevicesPulldown"
                itemName="deviceName"
                labelName="Select HST Device"
                highLightFlag={true}
              />
            </>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Ship Date*"
                inputFormat="MM/DD/YYYY"
                value={SHIP || ""}
                onChange={updateSHIP}
                readOnly={isPreviousVisits}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    error={formErrorState.shipDate?.error && !SHIP}
                    sx={{ width: "100%" }}
                    helperText={formErrorState.shipDate?.error && !SHIP ? "required" : ""}
                    value={
                      SHIP ? moment.utc(SHIP.toLocaleString()).format("MM/DD/YYYY") : ""
                    }
                    inputProps={{ readOnly: isPreviousVisits }}
                    onBlur={() => {
                      setErrorSHIP(!SHIP);
                    }}
                    onMouseDown={handleFocus}
                    placeholder="mm/dd/yyyy"
                  />
                )}
              />
            </LocalizationProvider>{" "}

            {/* pickup label */}
            <Box>
            <FormControlLabel
              control={
                <Checkbox
                  id="pickup"
                  checked={Boolean(formState.pickup)}
                  onChange={pickupCheckboxChange}
                />
              }
              label="Pickup"
            />
            </Box>
          </div>
        )}
        {(formState?.studyTypeId === 8 ||
          formState?.studyTypeId === 9 ||
          formState?.studyTypeId === 10) && isPatientPortal &&
          <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            margin: "8px 0px",
            alignItems: "center"
          }}>
            <TextField
              sx={{ width: "100%" }}
              id="trackingNum"
              label="Inbound Tracking Number"
              value={formState?.trackingNum || ""}
              variant="standard"
              onChange={updateInput}
              inputProps={{ readOnly: isPreviousVisits }}
            />
            <TextField
              sx={{ width: "100%" }}
              id="outTrackingNum"
              label="Outbound Tracking Number"
              value={formState?.outTrackingNum || ""}
              variant="standard"
              onChange={updateInput}
              inputProps={{ readOnly: isPreviousVisits }}
            />
          </Box>
          }
        {(formState?.studyTypeId === 8 ||
          formState?.studyTypeId === 9 ||
          formState?.studyTypeId === 10) && isPatientPortal &&
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
              margin: "8px 0px",
              alignItems: "center"
            }}
          >
            <TextField
              sx={{ width: "50%", mt: 1 }}
              id="hstSerialNumber"
              label="HST Serial Number"
              value={formState?.hstSerialNumber || ""}
              variant="standard"
              onChange={updateInput}
              inputProps={{ readOnly: isPreviousVisits }}
            />
          </Box>
        }

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            margin: "8px 0px",
            alignItems: "center"
          }}
        >
          <TextField
            sx={{ width: "100%" }}
            id="orderingPhysicianName"
            data-testid="order-physician-id"
            label="Ordering Physician"
            variant="standard"
            value={formState.orderingPhysicianName || ""}
            inputProps={{ readOnly: isPreviousVisits }}
            // InputProps={{ disableUnderline: true }}
          />
          <PrefInterpPhysicianPulldown
            formState={formState}
            updateInput={updateInput}
            formErrorState={formErrorState}
            attributeName="prefInterpPhysId"
            isPreviousVisits={isPreviousVisits}
            isPatientPortal={isPatientPortal}
            physicianId={formState.physicianId}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
            margin: "8px 0px",
            alignItems: "center"
          }}>
          <FormControl
            variant="standard"
            sx={{ minWidth: "49%", margin: "8px 0px" }}
          >
            <InputLabel
              id="location-label"
              error={
                formErrorState?.locationId?.error && !formState?.locationId
              }
            >
              Facility *
            </InputLabel>
            <Select
              labelId="location-label"
              id="locationId"
              name="locationId"
              value={formState.locationId || ""}
              label="location"
              onChange={updateInput}
              required
              error={formErrorState.locationId?.error && !formState.locationId}
              inputProps={{ readOnly: isPreviousVisits }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {locations.map((location) => (
                <MenuItem value={location.locationId} key={location.locationId}>
                  {location.location}
                </MenuItem>
              ))}
            </Select>
            {formErrorState?.locationId?.error && !formState?.locationId && (
              <Typography variant="subtitle2" color={"error"}>
                required
              </Typography>
            )}
          </FormControl>

        { (formState?.studyTypeId === 8 ||
          formState?.studyTypeId === 9 ||
          formState?.studyTypeId === 10) && 
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Device Return Due Date *"
                inputFormat="MM/DD/YYYY"
                className="study-date-data"
                value={deviceReturnDate || ""}
                onChange={updateDueDate}
                readOnly={isPreviousVisits}
                renderInput={(params) => (
                  <TextField
                    // data-testid="study-date-data"
                    {...params}
                    variant="standard"
                    error={deviceReturnDateErr && !deviceReturnDate}
                    helperText={
                      deviceReturnDateErr && !deviceReturnDate ? "required" : ""
                    }
                    value={
                      deviceReturnDate ? moment.utc(deviceReturnDate.toLocaleString()).format("MM/DD/YYYY") : ""
                    }
                    onBlur={() => {
                      setDeviceReturnDateErr(!deviceReturnDate);
                    }}
                    inputProps={{ readOnly: isPreviousVisits }}
                    onMouseDown={handleFocus}
                    placeholder="mm/dd/yyyy"
                  />
                )}
              />
            </LocalizationProvider>
          </FormControl>}
        </Box>
        {!asDialog && !isPreviousVisits && isWellnecessitiesUser && (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              disabled={isSaveButtonDisabled}
              sx={{ margin: "1rem 0rem" }}
              onClick={saveChanges}
              data-testid="schedule-save-Button"
            >
              Save Changes
            </Button>
          </div>
        )}
      </div>
    );
  }
}
